import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { Api } from '../utility/client';

type Props = Omit<
  UseQueryOptions<
    SelectOptionsTypes.UnitOption[],
    Error,
    SelectOptionsTypes.UnitOption[],
    string[]
  >,
  'queryKey' | 'queryFn'
>;

export const useUnitOptions = (props: Props = {}) => {
  const { user } = useCurrentUser();

  return useQuery({
    ...props,
    queryKey: ['unit-options', user?.domainId as string],

    queryFn: async () => {
      const url = '/selectOptions/v1/units';
      const res = await Api.get<SelectOptionsTypes.UnitOption[]>(url);

      return res?.data;
    },
  });
};
