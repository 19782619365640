import { logger } from '@inspiren-monorepo/util-logger';

import type { ActivityEvent } from './types';

const KEEP_ACTIVE_INTERVAL = 39 * 1000;

export interface ActivityTrackerOptions {
  dispatch?: <Event extends ActivityEvent>(event: Event) => void;
}

export abstract class ActivityTracker {
  protected isInitialized: boolean;
  protected options: ActivityTrackerOptions = {};
  protected sessionId: string | null;
  protected firstActivityTime: Date | null;
  protected lastActivityTime: Date | null;
  protected lastSentActivityTime: Date | null;

  protected cleanListeners: () => void;
  protected clearIntervals: () => void;

  protected abstract generateSessionId(): string;
  protected abstract registerEventListeners(): () => void;

  start() {
    if (this.isInitialized) {
      return;
    }

    this.cleanListeners = this.registerEventListeners();
    this.clearIntervals = this.startTracking();

    this.isInitialized = true;
  }

  stop() {
    if (!this.isInitialized) {
      return;
    }

    this.sendActivityEvent();

    this.resetSession();
    this.cleanListeners();
    this.clearIntervals();

    this.isInitialized = false;
  }

  updateOptions(options: ActivityTrackerOptions) {
    this.options = { ...options };
  }

  getSessionId() {
    return this.sessionId;
  }

  keepActive() {
    if (!this.isInitialized) {
      return;
    }

    // create session on the first event capture
    if (!this.sessionId) {
      this.sessionId = this.generateSessionId();
      this.firstActivityTime = new Date();
    }

    this.lastActivityTime = new Date();
  }

  protected resetSession() {
    this.lastActivityTime = null;
    this.firstActivityTime = null;
    this.sessionId = null;
  }

  protected sendActivityEvent() {
    if (!this.lastActivityTime || !this.firstActivityTime) {
      return;
    }

    if (this.lastSentActivityTime === this.lastActivityTime) {
      return;
    }

    if (!this.sessionId) {
      return;
    }

    if (!this.options.dispatch) {
      logger.warn(
        'There is no dispatch function provided to the ActivityTracker',
      );

      return;
    }

    this.options.dispatch({
      firstActivityTime: this.firstActivityTime?.toISOString(),
      lastActivityTime: this.lastActivityTime?.toISOString(),
      sessionId: this.sessionId,
    });

    this.lastSentActivityTime = this.lastActivityTime;
  }

  protected startTracking() {
    const sendActivityEvent = this.sendActivityEvent.bind(this);
    const resetSession = this.resetSession.bind(this);

    const intervalId = setInterval(async () => {
      const now = new Date();

      if (
        this.lastActivityTime &&
        now.getTime() - this.lastActivityTime.getTime() <= KEEP_ACTIVE_INTERVAL
      ) {
        sendActivityEvent();
      } else {
        resetSession();
      }
    }, KEEP_ACTIVE_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }
}
