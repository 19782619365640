import { useMemo } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import SelectUnit from './SelectUnit';

import type { UserFields } from '../helpers/getUsersTableFields';

type Props = {
  options?: SelectOptionsTypes.UnitOption[];
};

const ControlledSelectedUnit = ({ options }: Props) => {
  const { control, watch } = useFormContext<UserFields>();

  const { levelAccessBuildingIds, levelAccess } = watch();

  const unitOptions = useMemo(
    () =>
      (options || []).filter((unit) => {
        if (levelAccess === 'building') {
          return levelAccessBuildingIds.includes(unit.buildingId);
        }

        return true;
      }),
    [options, levelAccess, levelAccessBuildingIds],
  );

  return (
    <Controller
      name='unitId'
      control={control}
      defaultValue=''
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <SelectUnit
            error={error}
            onChange={onChange}
            value={value}
            id={name}
            label='Unit'
            options={unitOptions}
          />
          <FormHelperText error={Boolean(error)}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default ControlledSelectedUnit;
