import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useCurrentUser } from './HOC/CurrentUserContextProvider';
import ProtectedComponent from './HOC/ProtectedComponent';
import { useLogin } from './HOC/hooks/useLogin';
import Layout from './components/shared/Layout';
import Admin from './screens/Admin';
import AnalyticsScreen from './screens/AnalyticsScreen';
import ErrorScreen from './screens/ErrorScreen';
import EventReviewScreen from './screens/EventReviewScreen';
import FallVideoScreen from './screens/FallVideoScreen';
import Loading from './screens/Loading';
import Periscope from './screens/Periscope';
import Unauthorized from './screens/Unauthorized';
import { screens } from './screens/screens';
import { configAmp } from './utility/amplitude';
import { addAuthHeader } from './utility/client';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  const [loading, setLoading] = useState(true);
  const { setUser } = useCurrentUser();

  const {
    isLoading: authLoading,
    getAccessTokenSilently: getToken,
    user: authUser,
  } = useAuth0();

  const { user, loading: userLoading, error } = useLogin(authUser?.email);

  useEffect(() => {
    if (!authLoading && !userLoading) {
      setUser(user);

      if (user) {
        configAmp(user);
      }

      addAuthHeader(getToken);
      setLoading(false);
    }
  }, [authLoading, userLoading, user, getToken, setUser, setLoading]);

  const accessibleScreens = useMemo(() => {
    const filteredScreens = screens.filter(({ subject }) =>
      user?.ability?.can('view', subject),
    );

    if (filteredScreens.length === 0) {
      filteredScreens.push(screens[0]);
    }

    return filteredScreens;
  }, [screens, user]);

  if (loading) return <Loading />;

  if (error) {
    console.error(error);
    return <Unauthorized />;
  }

  return (
    <SentryRoutes>
      <Route path='/*' element={<ProtectedComponent component={<Layout />} />}>
        <Route
          path='rooms/*'
          element={
            <ProtectedComponent
              component={<Periscope />}
              otherCheck={user?.ability?.can('view', 'virtual-care.rooms')}
            />
          }
        />
        <Route
          path='event-review'
          element={
            <ProtectedComponent
              component={<EventReviewScreen />}
              otherCheck={user?.ability?.can(
                'view',
                'virtual-care.event-review',
              )}
            />
          }
        />
        <Route
          path='analytics/:viewId?'
          element={
            <ProtectedComponent
              component={<AnalyticsScreen />}
              otherCheck={user?.ability?.can('view', 'virtual-care.analytics')}
            />
          }
        />
        <Route
          path='admin/*'
          element={
            <ProtectedComponent
              component={<Admin />}
              otherCheck={user?.ability?.can('view', 'virtual-care.admin')}
            />
          }
        />
        <Route path='*' element={<Navigate to={accessibleScreens[0].path} />} />
      </Route>
      <Route path='/fv/:exportId' element={<FallVideoScreen />} />
      <Route path='/401' element={<Unauthorized />} />
      <Route path='/403' element={<ErrorScreen defaultMessage='Forbidden' />} />
      <Route path='/' element={<Navigate to={accessibleScreens[0].path} />} />
    </SentryRoutes>
  );
};

export default App;
