import { cloneElement, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { isNil } from 'lodash';

import FormModalBase from './FormModalBase';

import type { OnSubmitFormModal, RenderFormModal } from './FormModalBase';
import type { DataFields, FieldTypes } from '../types/DataFields';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';

type Props<F extends FieldTypes> = {
  itemName: string;
  fields: DataFields<F>;
  initialValues?: F;
  onSubmit?: OnSubmitFormModal<F>;
  loading?: boolean;
  error?: string | null | boolean;
  isSaveError?: boolean;
  onOpen?: () => void;
  renderModal?: RenderFormModal<F>;
  openIcon?: EmotionJSX.Element;
  disabled?: boolean;
  children?: JSX.Element;
};

const EditModal = <F extends FieldTypes>({
  itemName,
  fields,
  initialValues,
  loading = false,
  error = null,
  isSaveError,
  onOpen,
  onSubmit,
  renderModal,
  openIcon,
  disabled = false,
  children,
}: Props<F>) => {
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  // Modal will unmount half a second after closing, enough time for transition
  useEffect(() => {
    if (open) {
      setMounted(true);
    } else {
      setTimeout(() => {
        setMounted(false);
      }, 500);
    }
  }, [open]);

  const handleOpen = () => {
    if (onOpen) onOpen();
    setOpen(true);
  };

  return (
    <>
      {children && cloneElement(children, { onClick: handleOpen, disabled })}
      {isNil(children) && (
        <IconButton size='small' onClick={handleOpen} disabled={disabled}>
          {openIcon || <Edit fontSize='inherit' />}
        </IconButton>
      )}
      {mounted && initialValues && (
        <FormModalBase<F>
          type='edit'
          itemName={itemName}
          fields={fields}
          initialValues={initialValues}
          open={open}
          loading={loading}
          error={error}
          isSaveError={isSaveError}
          handleClose={() => setOpen(false)}
          onSubmit={async (data) => {
            if (onSubmit) return onSubmit(data);
          }}
          renderModal={renderModal}
        />
      )}
    </>
  );
};

export default EditModal;
