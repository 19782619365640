import { useCallback, useMemo, useState } from 'react';
import {
  DataGridPro,
  type GridActionsColDef,
  type GridRowParams,
  type DataGridProProps,
  type GridSlots,
  type GridSlotProps,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridSortDirection,
} from '@mui/x-data-grid-pro';
import { isEmpty, isString } from 'lodash';

import type { BulkUpdatePayload } from '@inspiren-monorepo/shared-types';

import { Toolbar, type ToolbarProps } from './components/Toolbar';
import { getGridColDefsFromDataFields } from './helpers/getGridColDefsFromDataFields';

import EditModal from '../../modals/EditModal';
import TableError from '../../tables/TableError';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../../types/DataFields';

type Props<
  F extends FieldTypes,
  TBulkUpdatePayload extends BulkUpdatePayload = BulkUpdatePayload,
> = {
  itemName: string;
  fields: DataFields<F>;
  data?: DataGridProProps['rows'];
  modalLoading?: boolean;
  error?: string | null | boolean;
  modalError?: string | null | boolean;
  onModalOpen?: () => void;
  onEditSubmit?: OnSubmitFormModal<F>;
  disableEditing?: boolean;
  defaultSort?: keyof F & string;
  defaultSortDirection?: GridSortDirection;
  defaultPinnedColumns?: (keyof F & string)[];
  customNoRowsText?: string;
  renderModal?: RenderFormModal<F>;
  getExtraRowActions?: GridActionsColDef['getActions'];
} & Omit<
  DataGridProProps,
  | 'apiRef'
  | 'checkboxSelection'
  | 'checkboxSelectionVisibleOnly'
  | 'columns'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'localeText'
  | 'onRowSelectionModelChange'
  | 'pageSizeOptions'
  | 'rows'
  | 'slotProps'
  | 'slots'
> &
  Pick<
    ToolbarProps<F, TBulkUpdatePayload>,
    | 'bulkUpdateActionsDefinition'
    | 'bulkUpdateMutationFn'
    | 'disableAddButton'
    | 'enableBulkUpdate'
    | 'extraActionButtons'
    | 'extraTableOptions'
    | 'onAddSubmit'
    | 'onBulkUpdateSuccess'
    | 'showAddButton'
    | 'showExportMenu'
  >;

const TableBase = <
  F extends FieldTypes,
  TBulkUpdatePayload extends BulkUpdatePayload = BulkUpdatePayload,
>(
  props: Props<F, TBulkUpdatePayload>,
) => {
  const {
    itemName,
    fields,
    data = [],
    modalLoading = false,
    error,
    modalError = null,
    onModalOpen,
    onEditSubmit,
    onAddSubmit,
    disableEditing = false,
    defaultSort,
    defaultSortDirection,
    defaultPinnedColumns = [],
    showExportMenu,
    customNoRowsText,
    extraActionButtons,
    extraTableOptions,
    showAddButton,
    disableAddButton,
    renderModal,
    getExtraRowActions,
    enableBulkUpdate,
    bulkUpdateActionsDefinition,
    bulkUpdateMutationFn,
    onBulkUpdateSuccess,
    ...dataGridProps
  } = props;

  const apiRef = useGridApiRef();

  const [panelAnchorEl, setPanelAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const [areRowsSelected, setAreRowsSelected] = useState(false);

  const getActions = useCallback(
    (params: GridRowParams) => {
      const actions: JSX.Element[] = [];

      if (!disableEditing) {
        actions.push(
          <EditModal<F>
            itemName={itemName}
            fields={fields}
            initialValues={params.row}
            loading={modalLoading}
            error={modalError}
            onSubmit={onEditSubmit}
            onOpen={onModalOpen}
            renderModal={renderModal}
            disabled={areRowsSelected}
          />,
        );
      }

      if (getExtraRowActions) {
        actions.push(...getExtraRowActions(params));
      }

      return actions;
    },
    [
      disableEditing,
      itemName,
      fields,
      modalLoading,
      modalError,
      onEditSubmit,
      onModalOpen,
      getExtraRowActions,
      renderModal,
      areRowsSelected,
    ],
  );

  const actionColumn: GridActionsColDef = useMemo(
    () => ({
      field: 'Actions',
      type: 'actions',
      width: 50,
      hideable: false,
      resizable: false,
      getActions,
    }),
    [getActions],
  );

  const dataColumns = useMemo(
    () => getGridColDefsFromDataFields(fields),
    [fields],
  );

  const columns = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        headerName: 'Select',
      },
      actionColumn,
      ...dataColumns,
    ],
    [actionColumn, dataColumns],
  );

  const errorMessage = useMemo(() => {
    if (error === true) return '';
    if (isString(error)) return error;
    return null;
  }, [error]);

  const modalErrorMessage = useMemo(() => {
    if (modalError === true) return '';
    if (isString(modalError)) return modalError;
    return null;
  }, [modalError]);

  const toolbarProps: ToolbarProps<F, TBulkUpdatePayload> = useMemo(
    () => ({
      itemName,
      fields,
      setPanelAnchorEl,
      onAddSubmit,
      onAddOpen: onModalOpen,
      addLoading: modalLoading,
      addError: modalErrorMessage,
      showExportMenu,
      extraActionButtons,
      extraTableOptions,
      disableAddButton,
      showAddButton,
      renderModal,
      areRowsSelected,
      enableBulkUpdate,
      bulkUpdateActionsDefinition,
      bulkUpdateMutationFn,
      onBulkUpdateSuccess,
    }),
    [
      itemName,
      fields,
      setPanelAnchorEl,
      onAddSubmit,
      onModalOpen,
      modalLoading,
      modalErrorMessage,
      showExportMenu,
      extraActionButtons,
      extraTableOptions,
      disableAddButton,
      showAddButton,
      renderModal,
      areRowsSelected,
      enableBulkUpdate,
      bulkUpdateActionsDefinition,
      bulkUpdateMutationFn,
      onBulkUpdateSuccess,
    ],
  );

  const deselectAllRows = useCallback(() => {
    apiRef.current.setRowSelectionModel([]);
  }, [apiRef]);

  return errorMessage ? (
    <TableError error={errorMessage} />
  ) : (
    <DataGridPro
      {...dataGridProps}
      apiRef={apiRef}
      columns={columns}
      rows={data}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 25 },
        },
        sorting: {
          sortModel: [
            { field: defaultSort || 'id', sort: defaultSortDirection || 'asc' },
          ],
        },
        pinnedColumns: { left: ['Actions', ...defaultPinnedColumns] },
      }}
      slots={{
        toolbar: Toolbar as GridSlots['toolbar'],
      }}
      slotProps={{
        panel: {
          anchorEl: panelAnchorEl,
          placement: 'bottom-end',
        },
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'skeleton',
        },
        toolbar: toolbarProps as GridSlotProps['toolbar'],
      }}
      localeText={{ noRowsLabel: customNoRowsText || 'No rows' }}
      checkboxSelection={enableBulkUpdate}
      checkboxSelectionVisibleOnly
      disableRowSelectionOnClick
      onRowSelectionModelChange={(selection) =>
        setAreRowsSelected(!isEmpty(selection))
      }
      onSortModelChange={deselectAllRows}
      onFilterModelChange={deselectAllRows}
      onPaginationModelChange={deselectAllRows}
    />
  );
};

export default TableBase;
