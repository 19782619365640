import { useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import type { GridRowId } from '@mui/x-data-grid-pro';
import { type MutationFunction, useMutation } from '@tanstack/react-query';
import { type DefaultValues, useForm } from 'react-hook-form';

import type { BulkUpdatePayload } from '@inspiren-monorepo/shared-types';

import { getLabeledItemCount } from '../helpers/getLabeledItemCount';
import { BulkUpdateStep, useBulkUpdateStep } from '../hooks/useBulkUpdateStep';

import type { FieldTypes } from '../../../types/DataFields';
import type { BulkUpdateActionDefinition } from '../types/BulkUpdateFields';
import type { PopupState } from 'material-ui-popup-state/hooks';

interface Props<
  TRowFields extends FieldTypes,
  Payload extends BulkUpdatePayload,
> {
  itemName: string;
  popupState: PopupState;
  selectedRows: Omit<Map<GridRowId, TRowFields>, 'set' | 'clear' | 'delete'>;
  fieldToUpdate: BulkUpdateActionDefinition<Payload>;
  getRowDisplayName: (row: TRowFields) => string;
  mutationFn: MutationFunction<void, Payload>;
  onSuccess?: (variables: Payload) => void;
}

export const BulkUpdateModal = <
  F extends FieldTypes,
  Payload extends BulkUpdatePayload,
>({
  itemName,
  popupState,
  selectedRows,
  fieldToUpdate,
  getRowDisplayName,
  mutationFn,
  onSuccess,
}: Props<F, Payload>) => {
  const needsFollowUp = Boolean(fieldToUpdate.renderFollowUpModal);
  const { step, goToNextStep, goToFollowUp } = useBulkUpdateStep(needsFollowUp);

  const { control, handleSubmit } = useForm<Payload>({
    defaultValues: {
      ids: [...selectedRows.keys()],
      action: fieldToUpdate.action,
    } as DefaultValues<Payload>,
  });

  const { mutate, isSuccess } = useMutation({
    mutationFn,
    onSettled: goToNextStep,
    onMutate: goToNextStep,
    onSuccess: (_data, variables) => onSuccess?.(variables),
  });

  const formattedRoomsToUpdate = useMemo(
    () =>
      [...selectedRows.values()]
        .map(getRowDisplayName)
        .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
        .join(', '),
    [selectedRows],
  );

  return (
    <form onSubmit={handleSubmit((action) => mutate(action))}>
      <DialogTitle>
        Bulk Update {getLabeledItemCount(selectedRows.size, itemName, true)}
      </DialogTitle>
      <DialogContent>
        {step === BulkUpdateStep.FollowUp &&
          fieldToUpdate.renderFollowUpModal?.({
            action: fieldToUpdate.action,
            control,
          })}
        {step === BulkUpdateStep.Confirm && (
          <>
            <DialogContentText sx={{ color: 'black', mb: 1 }}>
              Are you sure you want to {fieldToUpdate.description} for the
              following rooms?
            </DialogContentText>
            <DialogContentText>{formattedRoomsToUpdate}</DialogContentText>
          </>
        )}
        {step === BulkUpdateStep.Pending && (
          <Box sx={{ width: '100%' }}>
            <CircularProgress sx={{ mx: 'auto' }} />
          </Box>
        )}
        {step === BulkUpdateStep.Result &&
          (isSuccess ? (
            <>
              <DialogContentText sx={{ color: 'black', mb: 1 }}>
                Success! The following rooms were updated:
              </DialogContentText>
              <DialogContentText>{formattedRoomsToUpdate}</DialogContentText>
            </>
          ) : (
            <DialogContentText sx={{ color: 'black' }}>
              There was an error while updating the rooms. Please try again.
            </DialogContentText>
          ))}
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        {step === BulkUpdateStep.FollowUp && (
          <>
            <Button onClick={popupState.close}>Cancel</Button>
            {/* TODO: validate first, if there's follow up data */}
            <Button onClick={goToNextStep} variant='contained'>
              Save
            </Button>
          </>
        )}
        {step === BulkUpdateStep.Confirm && (
          <>
            {needsFollowUp ? (
              <Button onClick={goToFollowUp}>Back</Button>
            ) : (
              <Button onClick={popupState.close}>Cancel</Button>
            )}
            <Button type='submit' variant='contained'>
              Confirm
            </Button>
          </>
        )}
        {step === BulkUpdateStep.Result && (
          <Button onClick={popupState.close}>Close</Button>
        )}
      </DialogActions>
    </form>
  );
};
