import type React from 'react';
import { useEffect, useMemo } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { combinations } from 'combinatorial-generators';
import { isString, keyBy, mapValues } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  type Control,
  type UseFormSetValue,
  FormProvider,
} from 'react-hook-form';
import * as yup from 'yup';

import { getDefaultComponentFromFieldData } from './helpers/getDefaultComponentFromFormFieldData';

import type { DataField, DataFields, FieldTypes } from '../types/DataFields';

export type OnSubmitFormModal<F extends FieldTypes> = (
  data: F,
) => Promise<unknown>;

export type RenderFormModal<F extends FieldTypes> = (formData: {
  control: Control;
  defaultComponents: Record<keyof F, JSX.Element | null>;
  fields: Record<keyof F, DataField<F>>;
  type: 'add' | 'edit';
  setValue: UseFormSetValue<Partial<F>>;
  initialValues?: F;
}) => React.ReactNode;

interface Props<F extends FieldTypes> {
  type: 'add' | 'edit';
  itemName: string;
  fields: DataFields<F>;
  initialValues?: F;
  open: boolean;
  loading?: boolean;
  error?: string | null | boolean;
  isSaveError?: boolean;
  handleClose: () => void;
  onSubmit: OnSubmitFormModal<F>;
  renderModal?: RenderFormModal<F>;
}

const FormModalBase = <F extends FieldTypes>({
  type,
  itemName,
  fields,
  initialValues,
  open,
  loading = false,
  error = null,
  isSaveError = false,
  handleClose,
  onSubmit,
  renderModal,
}: Props<F>) => {
  // Consolidate schema in each field into one object
  const objectSchema = useMemo(
    () =>
      mapValues(keyBy(fields, 'field'), (field) => {
        if (type === 'add' && field.addSchema) return field.addSchema;
        if (type === 'edit' && field.editSchema) return field.editSchema;
        if (field.schema) return field.schema;
        return yup.mixed().nullable().notRequired();
      }),
    [fields, type],
  );

  const excludedFields = useMemo(() => {
    const excluded = fields
      .filter((field) => field.schemaExclude)
      .map((field) => field.field) as string[];

    return [...combinations(excluded, 2)] as Array<[string, string]>;
  }, [fields]);

  const resolver = yupResolver(
    yup.object().shape(objectSchema, [...excludedFields]),
  );

  const form = useForm({
    // TODO: Fix type here. Related to TS issue with react-hook-form
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: initialValues as any,
    resolver,
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitting, isDirty, defaultValues },
  } = form;

  useEffect(() => {
    if (!defaultValues && initialValues && !isDirty) {
      reset(initialValues);
    }
  }, [defaultValues, initialValues, isDirty]);

  const handleFormClose = () => {
    if (!isSubmitting) {
      reset();
      handleClose();
    }
  };

  const onSubmitForm: OnSubmitFormModal<F> = async (data) => {
    await onSubmit(data);
    handleFormClose();
  };

  const modalContent = useMemo(() => {
    if (!renderModal) {
      return fields.map((field) =>
        getDefaultComponentFromFieldData(field, control, setValue, type),
      );
    }

    const keyByFields = keyBy(fields, 'field') as Record<keyof F, DataField<F>>;

    const defaultComponents = mapValues(keyByFields, (mapFields) =>
      getDefaultComponentFromFieldData(mapFields, control, setValue, type),
    );

    return renderModal({
      control,
      defaultComponents,
      fields: keyByFields,
      type,
      setValue,
      initialValues,
    });
  }, [fields, renderModal, control, type, setValue, itemName, initialValues]);

  const content = useMemo(() => {
    if (error) {
      const errorMessage = `Error ${isSaveError ? 'Saving' : 'Fetching'} data${isString(error) ? `: ${error}` : '.'}`;

      return (
        <>
          <DialogContent>
            <Alert severity='error'>{errorMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      );
    }

    if (loading) {
      return (
        <DialogContent sx={{ mx: 'auto', my: 6 }}>
          <CircularProgress />
        </DialogContent>
      );
    }

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <DialogContent>{modalContent}</DialogContent>
          <DialogActions>
            <Button onClick={handleFormClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              sx={{ ml: 1 }}
              loading={isSubmitting}
            >
              {type === 'add' ? 'Add' : 'Save'}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    );
  }, [
    error,
    handleClose,
    loading,
    handleSubmit,
    modalContent,
    handleFormClose,
    isSubmitting,
    onSubmitForm,
  ]);

  return (
    <Dialog open={open} onClose={handleFormClose} fullWidth>
      <DialogTitle>
        {type === 'add' ? 'Add' : 'Edit'} {itemName}
      </DialogTitle>
      {content}
    </Dialog>
  );
};

export default FormModalBase;
