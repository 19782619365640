import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { EditNote as UpdateIcon } from '@mui/icons-material';
import { type GridRowId, useGridApiContext } from '@mui/x-data-grid-pro';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
  bindDialog,
} from 'material-ui-popup-state/hooks';
import { useMap } from 'usehooks-ts';

import type { BulkUpdatePayload } from '@inspiren-monorepo/shared-types';

import { BulkUpdateModal } from './BulkUpdateModal';

import { getLabeledItemCount } from '../helpers/getLabeledItemCount';

import type { FieldTypes } from '../../../types/DataFields';
import type {
  BulkUpdateActionDefinition,
  SectionedBulkUpdateActions,
} from '../types/BulkUpdateFields';
import type { MutationFunction } from '@tanstack/react-query';

interface Props<Payload extends BulkUpdatePayload> {
  itemName: string;
  actionsDefinition: SectionedBulkUpdateActions<Payload>;
  mutationFn: MutationFunction<void, Payload>;
  onSuccess?: (variables: Payload) => void;
}

export const BulkUpdateMenu = <
  F extends FieldTypes = FieldTypes,
  Payload extends BulkUpdatePayload = BulkUpdatePayload,
>({
  itemName,
  actionsDefinition,
  mutationFn,
  onSuccess,
}: Props<Payload>) => {
  const apiRef = useGridApiContext();

  const menuState = usePopupState({
    variant: 'popover',
    popupId: `${itemName}-bulk-update-menu`,
  });

  const modalState = usePopupState({
    variant: 'dialog',
    popupId: `${itemName}-bulk-update-modal`,
  });

  const [selectedRows, selectedRowsActions] = useMap<GridRowId, F>();

  const [fieldToUpdate, setFieldToUpdate] =
    useState<BulkUpdateActionDefinition<Payload>>();

  useEffect(() => {
    if (menuState.isOpen) {
      selectedRowsActions.setAll(
        apiRef.current.getSelectedRows() as Map<GridRowId, F>,
      );
    }
  }, [menuState.isOpen]);

  const handleActionClick = useCallback<
    (action: BulkUpdateActionDefinition<Payload>) => void
  >((action) => {
    setFieldToUpdate(action);
    modalState.open();
  }, []);

  return (
    <>
      <Button
        {...bindTrigger(menuState)}
        variant='contained'
        size='small'
        startIcon={<UpdateIcon />}
      >
        Bulk Update
      </Button>
      <Menu {...bindMenu(menuState)}>
        <Chip
          label={`${getLabeledItemCount(selectedRows.size, itemName)} selected`}
          variant='filled'
          sx={{ mx: 2, mb: 2, mt: 1 }}
        />

        {actionsDefinition.map((section, sectionIndex) => [
          <Divider
            /* eslint-disable-next-line react/no-array-index-key */
            key={sectionIndex}
            sx={{ mb: sectionIndex === 0 ? 1 : undefined }}
          />,
          ...section.map((option) => (
            <MenuItem
              key={option.action}
              onClick={() => handleActionClick(option)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.displayName}</ListItemText>
            </MenuItem>
          )),
        ])}
      </Menu>

      <Dialog
        {...bindDialog(modalState)}
        fullWidth
        TransitionProps={{ unmountOnExit: true }}
      >
        <BulkUpdateModal<F, Payload>
          itemName={itemName}
          popupState={modalState}
          selectedRows={selectedRows}
          fieldToUpdate={fieldToUpdate!}
          getRowDisplayName={(row) => row.displayName as string}
          mutationFn={mutationFn}
          onSuccess={onSuccess}
        />
      </Dialog>
    </>
  );
};
