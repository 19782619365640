import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import SelectUnitRoom from './components/SelectUnitRoom';
import { basestationsTableFields } from './constants/basestationsTableFields';
import useBasestationUpsert from './hooks/useBasestationUpsert';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import BulkImportLink from '../../components/BulkImportLink';
import { TableBase } from '../../components/TableBase';
import { getOrgs } from '../../data-access/getOrgs';
import { getUnits } from '../../data-access/getUnits';
import SelectVirtualScenario from '../../modals/special/SelectVirtualScenario';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

const BasestationsTable = () => {
  const { isAdmin } = useIsAdmin();

  const { isLoading: orgsLoading, isError: orgsError } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: unitsLoading, isError: unitsError } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const modalLoading = useMemo(
    () => orgsLoading || unitsLoading,
    [orgsLoading, unitsLoading],
  );

  const modalError = useMemo(
    () => orgsError || unitsError,
    [orgsError, unitsError],
  );

  const {
    basestations,
    basestationsError,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  } = useBasestationUpsert();

  const onEditSubmit: OnSubmitFormModal<AdminTypes.BasestationDto> =
    useCallback(
      async (item) => {
        try {
          await handleEditSubmit(item);
          toast.success('Successfully updated basestation');
        } catch (error) {
          const message =
            getAxiosErrorMessage(error) ??
            `Error updating basestation${error ? `: ${error}` : ''}`;

          toast.error(message);
        }
      },
      [handleEditSubmit],
    );

  const onAddSubmit: OnSubmitFormModal<AdminTypes.BasestationDto> = useCallback(
    async (item) => {
      try {
        await handleAddSubmit(item);
        toast.success('Successfully added new basestation');
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding basestation${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleAddSubmit],
  );

  const renderModal = useCallback<RenderFormModal<AdminTypes.BasestationDto>>(
    ({ defaultComponents, control, setValue }) => (
      <>
        {defaultComponents.id}
        {defaultComponents.version}
        <SelectUnitRoom control={control} setValue={setValue} />
        <SelectVirtualScenario control={control} />
      </>
    ),
    [],
  );

  return (
    <TableBase<AdminTypes.BasestationDto>
      itemName='Basestation'
      sx={{
        '& .basestations.unassigned': {
          color: (theme) => theme.palette.grey[400],
          fontStyle: 'italic',
        },
      }}
      fields={basestationsTableFields}
      data={basestations || []}
      loading={basestationsLoading}
      modalLoading={modalLoading}
      modalError={modalError}
      error={basestationsError}
      onEditSubmit={onEditSubmit}
      onAddSubmit={onAddSubmit}
      extraActionButtons={isAdmin && <BulkImportLink itemName='basestations' />}
      renderModal={renderModal}
      defaultPinnedColumns={['id']}
    />
  );
};

export default BasestationsTable;
