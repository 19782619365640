import type { GridColumnGroupingModel } from '@mui/x-data-grid-pro';

import type { DataFields } from '../../../types/DataFields';
import type { RoleFieldTypes } from '../types/RoleFieldTypes';

export const getColumnGroupsFromFields = (
  fields: DataFields<RoleFieldTypes>,
): GridColumnGroupingModel => {
  const settings: string[] = [];
  const globalPermissions: string[] = [];
  const virtualCareGeneralPermissions: string[] = [];
  const virtualCareRoomsPermissions: string[] = [];
  const virtualCareEventReviewPermissions: string[] = [];
  const virtualCareAdminPermissions: string[] = [];
  const mobilePermissions: string[] = [];
  const zuiPermissions: string[] = [];

  fields.forEach(({ field }) => {
    if (field.startsWith('global.')) {
      globalPermissions.push(field);
    } else if (field.startsWith('virtual-care.rooms.')) {
      virtualCareRoomsPermissions.push(field);
    } else if (field.startsWith('virtual-care.event-review.')) {
      virtualCareEventReviewPermissions.push(field);
    } else if (field.startsWith('virtual-care.admin.')) {
      virtualCareAdminPermissions.push(field);
    } else if (field.startsWith('virtual-care.')) {
      virtualCareGeneralPermissions.push(field);
    } else if (field.startsWith('mobile.')) {
      mobilePermissions.push(field);
    } else if (field.startsWith('zui.')) {
      zuiPermissions.push(field);
    } else {
      settings.push(field);
    }
  });

  return [
    {
      groupId: 'Settings',
      children: settings.map((field) => ({ field })),
    },
    {
      groupId: 'Global Permissions',
      children: globalPermissions.map((field) => ({ field })),
    },
    {
      groupId: 'Virtual Care Permissions',
      children: [
        {
          groupId: 'General',
          children: virtualCareGeneralPermissions.map((field) => ({
            field,
          })),
        },
        {
          groupId: 'Rooms Page',
          children: virtualCareRoomsPermissions.map((field) => ({ field })),
        },
        {
          groupId: 'Event Review Page',
          children: virtualCareEventReviewPermissions.map((field) => ({
            field,
          })),
        },
        {
          groupId: 'Admin Page',
          children: virtualCareAdminPermissions.map((field) => ({ field })),
        },
      ],
    },
    {
      groupId: 'Mobile Permissions',
      children: mobilePermissions.map((field) => ({ field })),
    },
    {
      groupId: 'ZUI Permissions',
      children: zuiPermissions.map((field) => ({ field })),
    },
  ];
};
