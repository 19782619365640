import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ControlledError } from '@inspiren-monorepo/shared-types';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { postBasestation, putBasestation } from '../../../../../utility';
import { getBasestations } from '../../../data-access/getBasestations';

import type { OnSubmitFormModal } from '../../../modals/FormModalBase';
import type { UseQueryOptions } from '@tanstack/react-query';

type Props = {
  queryOptions?: Pick<
    UseQueryOptions<
      AdminTypes.BasestationDto[],
      unknown,
      AdminTypes.BasestationDto[],
      string[]
    >,
    'refetchOnMount' | 'refetchOnWindowFocus'
  >;
};

const useBasestationUpsert = ({ queryOptions = {} }: Props = {}) => {
  const queryClient = useQueryClient();

  const {
    isLoading: basestationsLoading,
    isError: basestationsError,
    data: basestations,
  } = useQuery({
    ...queryOptions,
    queryKey: ['basestations'],
    queryFn: getBasestations,
  });

  const handleEditSubmit: OnSubmitFormModal<AdminTypes.BasestationDto> =
    useCallback(
      async ({ roomId, id, virtual, scenario, version, roomName }) => {
        const data = {
          id,
          roomId: roomId || null,
          virtual: virtual || false,
          scenario: scenario || null,
          version: version || null,
        };

        if (
          (basestations || []).find(
            (base) => base.roomId === roomId && base.id !== id,
          )
        ) {
          throw new ControlledError(
            `${roomName} already has a basestation assigned to it. Please remove it before assigning this one`,
          );
        }

        await putBasestation(data);

        await Promise.all([
          queryClient.invalidateQueries({ queryKey: ['demo-actions'] }),
          queryClient.invalidateQueries({ queryKey: ['basestations'] }),
        ]);
      },
      [basestations],
    );

  const handleAddSubmit: OnSubmitFormModal<AdminTypes.BasestationDto> =
    useCallback(
      async ({ roomId, id, virtual, scenario, version }) => {
        const data = {
          id,
          roomId: roomId || null,
          virtual: virtual || false,
          scenario: scenario || null,
          version: version || null,
        };

        if (
          (basestations || []).find(
            (base) => base.roomId === roomId && base.id !== id,
          )
        ) {
          throw new ControlledError(
            `${roomId} already has a basestation assigned to it. Please remove it before assigning this one`,
          );
        }

        await postBasestation(data);

        await Promise.all([
          queryClient.invalidateQueries({ queryKey: ['demo-actions'] }),
          queryClient.invalidateQueries({ queryKey: ['basestations'] }),
        ]);
      },
      [basestations],
    );

  return {
    basestations,
    basestationsError,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  };
};

export default useBasestationUpsert;
