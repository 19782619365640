import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';

import { postBuilding, putBuilding } from '../../../utility';
import { timeZones, usaStates } from '../../../utility/constants';
import { TableBase } from '../components/TableBase';
import { getBuildings } from '../data-access/getBuildings';
import { getOrgs } from '../data-access/getOrgs';
import SelectOrg from '../modals/special/SelectOrg';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../types/DataFields';

interface BuildingFields extends FieldTypes {
  id: string;
  buildingId: string;
  domainId: string;
  orgId: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  displayName: string;
  tz: string;
}

const fields: DataFields<BuildingFields> = [
  {
    field: 'buildingId',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\da-z]+$/i,
        'ID can only include letter and number characters',
      ),
  },
  {
    field: 'orgId',
    label: 'Organization',
    width: 150,
    schema: yup.string().required('Organization is required'),
    initialValue: import.meta.env.VITE_ORG_ID,
  },
  {
    field: 'displayName',
    label: 'Display Name',
    width: 200,
    editType: 'text',
    schema: yup.string().optional(),
  },
  {
    field: 'address',
    label: 'Street Address',
    width: 200,
    editType: 'text',
    schema: yup.string().required('Street address is required'),
  },
  {
    field: 'city',
    label: 'City',
    width: 120,
    editType: 'text',
    schema: yup.string().required('City is required'),
  },
  {
    field: 'state',
    label: 'State',
    width: 120,
    editType: 'select',
    type: 'singleSelect',
    options: usaStates,
    schema: yup.string().required('State is required'),
  },
  {
    field: 'zip',
    label: 'ZIP Code',
    width: 120,
    editType: 'text',
    schema: yup.string().required('ZIP Code is required'),
  },
  {
    field: 'country',
    label: 'Country',
    width: 120,
    editType: 'text',
    disabled: true,
    initialValue: 'USA',
    schema: yup.string().required('Country is required'),
  },
  {
    field: 'tz',
    label: 'Time Zone',
    width: 200,
    editType: 'select',
    type: 'singleSelect',
    options: timeZones,
    schema: yup.string().optional(),
  },
];

const BuildingsTable = () => {
  const queryClient = useQueryClient();

  const { isLoading: orgsLoading, isError: orgsError } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const {
    isLoading: buildingsLoading,
    isError: buildingsError,
    data: buildings,
  } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const data = useMemo(() => buildings || [], [buildings]);

  const handleEditSubmit: OnSubmitFormModal<BuildingFields> = async (
    building,
  ) => {
    try {
      await putBuilding(building);

      await queryClient.invalidateQueries({
        queryKey: ['buildings'],
      });

      toast.success(`Successfully updated building ${building.buildingId}`);
    } catch (error) {
      const message =
        getAxiosErrorMessage(error) ??
        `Error editing building${error ? `: ${error}` : ''}`;

      toast.error(message);
    }
  };

  const handleAddSubmit: OnSubmitFormModal<BuildingFields> = async (
    building,
  ) => {
    try {
      await postBuilding(building);

      await queryClient.invalidateQueries({
        queryKey: ['buildings'],
      });

      toast.success(`Successfully added building ${building.buildingId}`);
    } catch (error) {
      const message =
        getAxiosErrorMessage(error) ??
        `Error adding building${error ? `: ${error}` : ''}`;

      toast.error(message);
    }
  };

  const renderModal = useCallback<RenderFormModal<BuildingFields>>(
    ({ defaultComponents, control, type }) => (
      <>
        {defaultComponents.buildingId}
        <SelectOrg type={type} control={control} />
        {defaultComponents.displayName}
        {defaultComponents.address}
        {defaultComponents.city}
        {defaultComponents.state}
        {defaultComponents.zip}
        {defaultComponents.country}
        {defaultComponents.tz}
      </>
    ),
    [],
  );

  return (
    <TableBase<BuildingFields>
      itemName='Building'
      fields={fields}
      data={data}
      defaultSort='buildingId'
      loading={buildingsLoading}
      error={buildingsError}
      modalLoading={orgsLoading}
      modalError={orgsError}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
      renderModal={renderModal}
      defaultPinnedColumns={['buildingId', 'displayName']}
    />
  );
};

export default BuildingsTable;
