import { validate } from 'uuid';

import type { FallRiskLevel } from '@inspiren-monorepo/shared-types';
import { isValidFallRiskLevel } from '@inspiren-monorepo/shared-types';
import type {
  SelectOptionsTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { isValidLevelAccess } from '../../../../../../types/LevelAccess';

import type { RoleMap, LevelAccess } from '../../../../../../types';

export type CsvCellMapper<T> = (input?: string) => T;

const csvCellMappers = {
  String: (input?: string): string => input?.trim() || '',
  StringLowercase: (input?: string): string =>
    input?.trim()?.toLocaleLowerCase() || '',
  StringPredefined:
    (allowed: string[], caseInsensitive?: boolean) =>
    (input?: string): string => {
      const fixedInput = caseInsensitive
        ? csvCellMappers.StringLowercase(input)
        : csvCellMappers.String(input);

      const equivalent = allowed.find((e) => {
        const value = caseInsensitive ? e.toLocaleLowerCase() : e;
        return value === fixedInput;
      });

      return equivalent ?? '';
    },
  Boolean: (input?: string): boolean => {
    const allowedValues = ['yes', 'true', '1'];
    const fixedInput = input?.trim()?.toLocaleLowerCase();

    return !!fixedInput && allowedValues.includes(fixedInput);
  },
  FallRiskLevel: (input?: string): FallRiskLevel => {
    const fixedInput = input?.trim()?.toLocaleLowerCase();

    if (!!fixedInput && isValidFallRiskLevel(fixedInput)) {
      return fixedInput;
    }

    return '' as FallRiskLevel;
  },
  LevelAccess: (input?: string): LevelAccess => {
    const fixedInput = input?.trim()?.toLocaleLowerCase();

    if (!!fixedInput && isValidLevelAccess(fixedInput)) {
      return fixedInput;
    }

    return 'unit' as LevelAccess;
  },
  Role:
    (roleMap?: RoleMap) =>
    (input?: string): string => {
      if (!roleMap || !input) {
        return '';
      }

      const fixedInput = input.trim().toLocaleLowerCase();

      const role = Object.entries(roleMap).find(
        ([id, details]) =>
          fixedInput === id.toLocaleLowerCase() ||
          fixedInput === details.displayName.toLocaleLowerCase(),
      );

      return role?.[0] ?? '';
    },
  Unit:
    (units?: SelectOptionsTypes.UnitOption[]) =>
    (input?: string): string => {
      if (!units || !input) {
        return '';
      }

      let fixedInput = input.trim().toLocaleLowerCase();

      if (fixedInput === 'unassigned') {
        return '';
      }

      if (validate(fixedInput)) {
        // if guid - we treat it as "id"
        return (
          units.find(({ id }) => id.toLocaleLowerCase() === fixedInput)?.id ??
          ''
        );
      }

      // otherwise we treat is as domainId
      if (fixedInput.startsWith('room-')) {
        fixedInput = fixedInput.slice(5);
      }

      return (
        units.find(
          ({ domainId }) =>
            domainId.toLocaleLowerCase() ===
            `${import.meta.env.VITE_ORG_ID.toLocaleLowerCase()}-${fixedInput}`,
        )?.id ?? ''
      );
    },
};

export default csvCellMappers;
