import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { isUsernameOrg } from '@inspiren-monorepo/util-users';
import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import ChangePassword from './ChangePassword';
import ControlledSelectLevelAccessBuildings from './ControlledSelectLevelAccessBuildings';
import ControlledSelectUnit from './ControlledSelectUnit';
import { SelectLevelAccess } from './SelectLevelAccess';
import SelectRole from './SelectRole';

import OrgHeader from '../../../components/OrgHeader';

import type { DataField, FieldTypes } from '../../../types/DataFields';
import type { RoleOption } from '../helpers/getRoleOptions';
import type { UserFields } from '../helpers/getUsersTableFields';

export type UserFormOrg = {
  id: string;
  displayName: string;
};

type Props<F extends FieldTypes> = {
  isAdmin: boolean;
  selectedOrg?: UserFormOrg;
  type: 'add' | 'edit';
  defaultComponents: Record<keyof F, JSX.Element | null>;
  fields: Record<keyof F, DataField<F>>;
  unitOptions: SelectOptionsTypes.UnitOption[];
  buildingOptions: SelectOptionsTypes.BuildingOption[];
  showPassword: boolean;
};

export const UserForm = <F extends FieldTypes>({
  isAdmin,
  selectedOrg,
  type,
  defaultComponents,
  fields,
  showPassword,
  unitOptions,
  buildingOptions,
}: Props<F>) => {
  const { watch, setValue } = useFormContext<UserFields>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'levelAccess' && value.levelAccess !== 'building') {
        const unit = unitOptions?.find((unit) => unit.id === value.unitId);
        setValue('levelAccessBuildingIds', unit ? [unit.buildingId] : []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, unitOptions]);

  return (
    <>
      {isAdmin && (
        <>
          <OrgHeader
            displayName={selectedOrg?.displayName}
            id={selectedOrg?.id}
          />
          {defaultComponents.id}
        </>
      )}
      {defaultComponents.firstName}
      {defaultComponents.lastName}
      {selectedOrg?.id &&
        isUsernameOrg(selectedOrg.id) &&
        defaultComponents.username}
      {defaultComponents.email}
      {defaultComponents.mobilePhone}
      {type === 'add' && defaultComponents.password}
      {type === 'edit' ? (
        <SelectRole items={fields.role.options as RoleOption[]} />
      ) : (
        defaultComponents.role
      )}
      <SelectLevelAccess />
      <ControlledSelectLevelAccessBuildings options={buildingOptions} />
      <ControlledSelectUnit options={unitOptions} />
      {showPassword && <ChangePassword />}
    </>
  );
};
