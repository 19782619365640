import { Controller, useFormContext, useWatch } from 'react-hook-form';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import SelectLevelAccessBuildings from './SelectLevelAccessBuildings';

type Props = {
  options?: SelectOptionsTypes.BuildingOption[];
};

const ControlledSelectLevelAccessBuildings = ({ options }: Props) => {
  const { control } = useFormContext();
  const selectedLevelAccess = useWatch({ name: 'levelAccess', control });

  if (selectedLevelAccess !== 'building') {
    return null;
  }

  return (
    <Controller
      name='levelAccessBuildingIds'
      control={control}
      defaultValue={[]}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <SelectLevelAccessBuildings
          error={error}
          onChange={onChange}
          value={value}
          id={name}
          label='Buildings'
          options={options}
        />
      )}
    />
  );
};

export default ControlledSelectLevelAccessBuildings;
