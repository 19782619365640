import { Link } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import * as yup from 'yup';

import { TableBase } from '../../components/TableBase';
import { getOrgs } from '../../data-access/getOrgs';

import type { DataFields, FieldTypes } from '../../types/DataFields';

export interface OrgFields extends FieldTypes {
  id: string;
  displayName: string;
  ipAllowList?: string[];
  roles?: string[];
}

const fields: DataFields<OrgFields> = [
  {
    field: 'id',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    renderCell: (params) => (
      <Link href={`/admin/organizations/${params.value}`}>{params.value}</Link>
    ),
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(/^[A-Z]+$/, 'ID can only contain capital letters'),
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 250,
    editType: 'text',
    schema: yup.string().required('You must provide an organization name'),
  },
  {
    field: 'ipAllowList',
    label: 'IP allowlist',
    width: 300,
    flex: 1,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
    hideOnEdit: true,
    valueFormatter: (value: OrgFields['ipAllowList']) => value?.join(', '),
  },
  {
    field: 'roles',
    label: 'Roles',
    width: 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
    hideOnEdit: true,
    flex: 2,
    valueFormatter: (value: OrgFields['roles']) => {
      const formattedValues = value
        ?.map((role) => role.charAt(0).toUpperCase() + role.slice(1))
        .sort();

      return formattedValues?.join(', ');
    },
  },
];

const OrganizationsTable = () => {
  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
    placeholderData: keepPreviousData,
  });

  return (
    <TableBase<OrgFields>
      itemName='Organization'
      fields={fields}
      data={orgs}
      loading={orgsLoading}
      error={orgsError}
      showAddButton={false}
      disableEditing
      defaultPinnedColumns={['id', 'displayName']}
    />
  );
};

export default OrganizationsTable;
