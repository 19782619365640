import { useFormContext } from 'react-hook-form';

import ControlledDropdown from '../../../modals/ControlledDropdown';

export const SelectLevelAccess = () => {
  const { control } = useFormContext();

  return (
    <ControlledDropdown
      id='levelAccess'
      label='Level Access'
      defaultValue='unit'
      items={[
        { label: 'Org', value: 'org' },
        { label: 'Building', value: 'building' },
        {
          label: 'Unit',
          value: 'unit',
        },
      ]}
      control={control}
    />
  );
};
