import { useCallback, useMemo } from 'react';
import { keyBy } from 'lodash';
import { useParams } from 'react-router-dom';

import getRoomsTableFields from './constants/getRoomsTableFields';
import useRoomsUpsert from './hooks/useRoomsUpsert';

import { useUnitOptions } from '../../../../hooks/useUnitOptions';
import { useOrgRooms } from '../../hooks/useOrgRooms';
import ImportTable from '../ImportTable';
import { mapCsvRowsToFields } from '../ImportTable/helpers/mapCsvRowsToFields';
import csvCellMappers from '../ImportTable/utils/csvCellMappers';

import type { RoomFieldTypes } from './types/RoomFieldTypes';
import type {
  FieldsConfiguration,
  Importable,
  ImportableDataFields,
} from '../ImportTable/types/importable';
import type { UploadFn } from '../ImportTable/types/uploadFn';

const RoomsImportTable = () => {
  const { org } = useParams<{ org: string }>();

  const { data: units, isLoading: unitsLoading } = useUnitOptions({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const unitsMap = useMemo(() => keyBy(units || [], 'id'), [units]);

  const { handleAddSubmit, handleEditSubmit } = useRoomsUpsert(unitsMap);

  const { isLoading: roomsLoading, rooms } = useOrgRooms({
    orgId: org,
    includeDeleted: true,
  });

  const fieldsConfiguration: FieldsConfiguration<RoomFieldTypes> = useMemo(
    () => ({
      roomId: {
        csvHeader: 'id',
        csvHeaderRegExp: /(roomId|id)/,
        mapper: csvCellMappers.String,
      },
      displayName: {
        csvHeader: 'display name',
        mapper: csvCellMappers.String,
      },
      unitId: {
        csvHeader: 'Building-Floor-Unit',
        csvHeaderRegExp: /building-floor-unit/i,
        mapper: csvCellMappers.Unit(units),
      },
      fallRiskLevel: {
        csvHeader: 'fall risk level',
        mapper: csvCellMappers.FallRiskLevel,
      },
      hide: {
        csvHeader: 'hidden',
        mapper: csvCellMappers.Boolean,
      },
      deleted: {
        csvHeader: 'archived',
        mapper: csvCellMappers.Boolean,
      },
    }),
    [org, units],
  );

  const columns: ImportableDataFields<RoomFieldTypes> = useMemo(
    () =>
      getRoomsTableFields(unitsMap, [org!]).filter(
        (e) => !!fieldsConfiguration[e.field],
      ),
    [org, unitsMap],
  );

  const createRoomDomainId = useCallback(
    (unitId: string, roomNumber: string) => {
      const unit = unitsMap[unitId];

      if (!unit) {
        return '';
      }

      return `Room-${unit.domainId}-${roomNumber}`;
    },
    [unitsMap],
  );

  const roomsMap = useMemo(() => keyBy(rooms || [], 'domainId'), [rooms]);

  const mapCsvToFields = useCallback(
    async (csvRows: string[][]): Promise<Importable<RoomFieldTypes>[]> =>
      mapCsvRowsToFields(csvRows, fieldsConfiguration, (e) => {
        const roomDomainId = createRoomDomainId(e.unitId, e.roomId);
        return !!roomsMap[roomDomainId];
      }),
    [roomsMap],
  );

  const createDefaultRow = useCallback(
    (): RoomFieldTypes =>
      ({
        id: '',
        displayName: '',
        organization: org,
        unitId: '',
        fallRiskLevel: 'off',
        hide: false,
        deleted: false,
      }) as RoomFieldTypes,
    [org],
  );

  const upload: UploadFn<RoomFieldTypes> = useCallback(
    async (item) => {
      const {
        $meta: { alreadyExists },
        ...rest
      } = item;

      if (alreadyExists) {
        const roomDomainId = createRoomDomainId(rest.unitId, rest.roomId);
        const roomGuid = roomsMap[roomDomainId]?.id;

        await handleEditSubmit({ ...rest, id: roomGuid });
      } else {
        await handleAddSubmit(rest);
      }
    },
    [handleAddSubmit, handleEditSubmit, createRoomDomainId, roomsMap],
  );

  const onRowUpdate = useCallback(
    (row: Importable<RoomFieldTypes>): Importable<RoomFieldTypes> => {
      const roomId = createRoomDomainId(row.unitId, row.roomId);

      return {
        ...row,
        $meta: {
          ...row.$meta,
          alreadyExists: (!!roomId && !!roomsMap[roomId]) || false,
        },
      };
    },
    [roomsMap, createRoomDomainId],
  );

  return (
    <ImportTable
      fields={columns}
      createDefaultRow={createDefaultRow}
      mapCsvToFields={mapCsvToFields}
      loading={roomsLoading || unitsLoading}
      upload={upload}
      itemName='rooms'
      heading={`Import rooms for org ${org}`}
      onRowUpdate={onRowUpdate}
      bulkUpdateSupport
    />
  );
};

export default RoomsImportTable;
