import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

import type { MutationFunction } from '@tanstack/react-query';

export const roomBulkUpdateMutationFn: MutationFunction<
  void,
  AdminTypes.RoomBulkUpdatePayload
> = async (data) => {
  const res = await Api.put('/admin/v1/rooms', data);
  return res.data;
};
